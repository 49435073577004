import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const useMyPageModify = () => {
  const ip = process.env.REACT_APP_URL
  const [profile, setProfile] = useState([])
  const [profileImage, setProfileImage] = useState()
  const [preProfileImage, setPreProfileImage] = useState()
  const [categoryList, setCategoryList] = useState([])
  const [categorySubList, setCategorySubList] = useState([])
  const [activeIndex, setActiveIndex] = useState()
  const fileInputRef = useRef(null)
  const [isValid, setIsValid] = useState(null)
  const [isCheck, setIsCheck] = useState(null)
  const formData = new FormData()
  const [categoryMap, setCategoryMap] = useState({})
  // const [map] = new Map()

  //ref
  const imageRef = useRef(null)
  const podCastRef = useRef(null)
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)
  const categoryRef = useRef(null)
  const subCategoryRef = useRef(null)

  const navigate = useNavigate()

  useEffect(() => {
    handleCategory()
    handleProfile()
  }, [])

  const handleProfile = async () => {
    try {
      await axios
        .get(`${ip}/channel`, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then((response) => {
          setProfile(response.data.data)
          setProfileImage(response.data.data.channelImage)
          setPreProfileImage(response.data.data.channelImage)
          setActiveIndex(Object.keys(response.data.data.channelCategories)[0]) //첫번째 key
          categoryRef.current.value = Object.keys(
            response.data.data.channelCategories,
          )[0]
          setIsCheck(response.data.data.ageLimit)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleCategory = async () => {
    try {
      await axios
        .get(`${ip}/category`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setCategoryList(response.data.data.categoryInfos)
          handleCategorySub(response.data.data.categoryInfos[0].name)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleCategorySub = async (name) => {
    try {
      await axios
        .get(`${ip}/category/sub`, {
          params: { name: name },
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setCategorySubList(response.data.data.categoryInfos)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleCategoryClick = (name) => {
    setActiveIndex(name)
    categoryRef.current.value = name
    handleCategorySub(name)
  }

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  // SubCategory 활성 상태를 관리하는 배열 상태 추가
  const [activeSubIndices, setActiveSubIndices] = useState([])

  // 서브 카테고리 클릭 핸들러 수정
  const handleSubButtonClick = (name) => {
    // categoryRef.current.value
    subCategoryRef.current.value = name

    setCategoryMap((prev) => {
      const category = categoryRef.current.value
      const currentValues = prev[category] || []

      // 값이 이미 존재하는지 확인
      if (currentValues.includes(name)) {
        // 존재하면 해당 값 제거
        return {
          ...prev,
          [category]: currentValues.filter((item) => item !== name),
        }
      } else {
        // 존재하지 않으면 추가
        return {
          ...prev,
          [category]: [...currentValues, name],
        }
      }
    })

    setActiveSubIndices(
      (prevItems) => {
        return prevItems.includes(name)
          ? prevItems.filter((i) => i !== name) // 배열에 있으면 제거
          : [...prevItems, name]
      }, // 없으면 추가
    )
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreProfileImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
    setProfileImage(event.target.files[0])
  }

  const handleImageRemove = () => {
    setPreProfileImage(null)
  }

  const handlePodcastName = async () => {
    try {
      await axios
        .get(`${ip}/member/check`, {
          params: { username: nameRef.current.value },
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setIsValid(response.data.data)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  //input 수정
  const handlePodcastNameChange = async (e) => {
    // profile.channelTitle = e.target.value;
    podCastRef.current.value = e.target.value
  }

  const handleNameChange = async (e) => {
    // profile.username = e.target.value;
    nameRef.current.value = e.target.value
  }

  const handleDescriptionChange = async (e) => {
    // profile.channelDescription = e.target.value;
    descriptionRef.current.value = e.target.value
  }

  const handleCheckboxChange = () => {
    setIsCheck(!isCheck)
  }

  //저장
  const handleSave = async () => {
    // const exMap = {
    //   Comedy: ['Comedy Interviews', 'Comedy'],
    //   Business: ['Careers'],
    // }
    const ChannelSaveRequestDto = {
      username: nameRef.current.value,
      channelTitle: podCastRef.current.value,
      channelDescription: descriptionRef.current.value, // JSON 문자열로 변환 시 문자열로 설정
      // channelCategories: [categoryRef.current.value],
      // channelDetailCategories: [subCategoryRef.current.value],
      // channelCategories: exMap,
      channelCategories: categoryMap,
      ageLimit: isCheck,
    }

    //  const response = await axios.get( profile.channelImage, { responseType: 'arraybuffer' });
    //  const base64Image = base64.fromByteArray(new Uint8Array(response.data.data));
    //  formData.append('image', base64Image);
    // formData.append('image', profile.channelImage);
    formData.append('image', profileImage)
    formData.append(
      'ChannelSaveRequestDto',
      JSON.stringify(ChannelSaveRequestDto),
    )

    try {
      await axios
        .patch(`${ip}/channel`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then(() => {
          navigate('/NewMyPage')
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  return {
    handleProfile,
    handleCategory,
    handleCategorySub,
    handleCategoryClick,
    handleImageClick,
    handleSubButtonClick,
    handleImageUpload,
    handleImageRemove,
    handlePodcastName,
    handlePodcastNameChange,
    handleNameChange,
    handleDescriptionChange,
    handleCheckboxChange,
    handleSave,
    profile,
    profileImage,
    preProfileImage,
    categoryList,
    categorySubList,
    activeIndex,
    fileInputRef,
    isValid,
    isCheck,
    formData,
    imageRef,
    podCastRef,
    nameRef,
    descriptionRef,
    categoryRef,
    subCategoryRef,
    activeSubIndices,
  }
}

export default useMyPageModify
