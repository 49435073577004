import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const useEpisodeList = () => {
  const ip = process.env.REACT_APP_URL
  const [activeTab, setActiveTab] = useState('1')
  const [episodeList, setEpisodeList] = useState([])
  const navigate = useNavigate()

  const handleTabClick = (tab) => {
    setActiveTab(tab)
    handleEpisode(tab)
  }

  useEffect(() => {
    handleEpisode(1)
  }, [])

  const handleEpisode = async (e) => {
    try {
      await axios
        .get(`${ip}/episode/list/${e}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setEpisodeList(response.data.data.episodeInfos)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleEpisodeDetail = (id) => {
    navigate(`/NewEpisodeManagement/${id}`)
  }

  const handleEpisodeCreate = () => {
    navigate(`/NewEpisodeCreate_v1`)
  }

  return {
    handleTabClick,
    handleEpisode,
    handleEpisodeDetail,
    handleEpisodeCreate,
    activeTab,
    episodeList,
  }
}

export default useEpisodeList
