import React from 'react'
import { Link } from 'react-router-dom'
import googleLogo from './google-logo.png'

const LoginComponent = () => {
  return (
    <div className="text-center mt-28">
      <div className="bg-white shadow-lg rounded-md w-96 inline-block mt-8 align-middle relative p-9 border border-blue-400">
        <h1 className="text-xl font-medium mt-0 mb-8 text-gray-700">
          Login to SpringSocial
        </h1>
        <div className="mb-4">
          <a
            className="flex items-center justify-center w-full py-2 px-4 text-gray-700 bg-white hover:bg-gray-100 rounded-md shadow-md"
            href="https://hzpodcaster.com/oauth2/authorization/google"
            // href="http://localhost:8080/oauth2/authorization/google"
          >
            <img src={googleLogo} alt="Google" className="h-5 mr-2" /> Log in
            with Google
          </a>
        </div>
        <div className="relative my-6">
          <span className="absolute inset-x-0 top-0 mx-auto -translate-y-1/2 bg-white px-2 text-gray-500">
            OR
          </span>
          <div className="border-t border-gray-300"></div>
        </div>
        <form>
          <div className="mb-4">
            <input
              type="email"
              name="email"
              className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              name="password"
              className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Password"
              required
            />
          </div>
          <div className="mb-4">
            <button
              type="submit"
              className="btn btn-block btn-primary w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Login
            </button>
          </div>
        </form>
        <span className="text-gray-500 text-sm">
          New user?{' '}
          <Link to="/signup" className="text-blue-500">
            Sign up!
          </Link>
        </span>
      </div>
    </div>
  )
}

export default LoginComponent
