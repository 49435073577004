import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'

const useEpisodeListDetail = () => {
  const ip = process.env.REACT_APP_URL
  const { id } = useParams()
  const [episodeDetail, setEpisodeDetail] = useState([])
  const [image, setImage] = useState(null)
  const [audio, setAudio] = useState(null)
  const [title] = useState('')
  const [description] = useState('')
  const [showSessionStorage] = useState(false)
  const fileInputRef = useRef(null)
  const [adIndex, setAdIndex] = useState([])

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleImageRemove = () => {
    setImage(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    handleEpisode(id)
  }, [])

  const handleEpisode = async (e) => {
    try {
      await axios
        .get(`${ip}/episode/${e}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setEpisodeDetail(response.data.data)
          setImage(response.data.data.image)
          setAudio(response.data.data.content)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  //audio waveform
  const wavesurferRef = useRef(null)
  const [playbackRate, setPlaybackRate] = useState(1.0)
  const audioInputRef = useRef(null)

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause()
    }
  }

  const handleSkipBackward = () => {
    if (wavesurferRef.current) {
      const currentTime = wavesurferRef.current.getCurrentTime()
      wavesurferRef.current.seekTo(
        (currentTime - 3) / wavesurferRef.current.getDuration(),
      )
    }
  }

  const handleSkipForward = () => {
    if (wavesurferRef.current) {
      const currentTime = wavesurferRef.current.getCurrentTime()
      wavesurferRef.current.seekTo(
        (currentTime + 3) / wavesurferRef.current.getDuration(),
      )
    }
  }

  const handlePlaybackRateChange = (rate) => {
    if (wavesurferRef.current) {
      wavesurferRef.current.setPlaybackRate(rate)
      setPlaybackRate(rate)
      sessionStorage.setItem('playbackRate', rate)
    }
  }

  const handleAudioRemove = () => {
    setAudio(null)
    if (audioInputRef.current) {
      audioInputRef.current.value = null
    }
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy()
      wavesurferRef.current = null
    }
    sessionStorage.removeItem('episodeAudio')
  }

  const handleAudioUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setAudio(reader.result)
        sessionStorage.setItem('episodeAudio', reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  // if문이 null이 뜸 -> 이거 해결하면 수정, 삭제 가능한데 모르겠음.
  const getRegionById = (id) => {
    const regionsPlugin = wavesurferRef.current
      .getActivePlugins()
      .find((plugin) => plugin instanceof RegionsPlugin)
    if (regionsPlugin && regionsPlugin.regions && regionsPlugin.regions.list) {
      return Object.values(regionsPlugin.regions.list).find(
        (region) => region.id === id,
      )
    }
    return null
  }

  const handleAddRegion = (startTime, content, index) => {
    const regionsPlugin = wavesurferRef.current
      .getActivePlugins()
      .find((plugin) => plugin instanceof RegionsPlugin)
    if (!regionsPlugin) {
      console.error('RegionsPlugin is not active')
      return
    }

    const existingRegion = getRegionById(index)

    if (existingRegion) {
      console.log('include')
      existingRegion.update({
        start: startTime,
        content: content,
        color: '#000000',
      })
    } else {
      console.log('not include')
      setAdIndex([...adIndex, index])
      regionsPlugin.addRegion({
        id: index,
        start: startTime,
        content: content,
        color: '#000000',
      })
    }
  }

  const handleRemoveRegion = (index) => {
    const regionsPlugin = wavesurferRef.current
      .getActivePlugins()
      .find((plugin) => plugin instanceof RegionsPlugin)
    if (!regionsPlugin) {
      console.error('RegionsPlugin is not active')
      return
    }
    console.log('remove be')
    const regionToRemove = getRegionById(index)
    if (regionToRemove) {
      console.log('remove suc')
      regionToRemove.remove()
      setAdIndex(adIndex.filter((id) => id !== index))
    }
  }

  return {
    handleButtonClick,
    handleImageRemove,
    handleImageUpload,
    handleEpisode,
    handlePlayPause,
    handleSkipBackward,
    handleSkipForward,
    handlePlaybackRateChange,
    handleAudioRemove,
    handleAudioUpload,
    handleAddRegion,
    handleRemoveRegion,
    episodeDetail,
    image,
    audio,
    title,
    description,
    showSessionStorage,
    fileInputRef,
    wavesurferRef,
    playbackRate,
    audioInputRef,
  }
}

export default useEpisodeListDetail
