import React from 'react'
import useMyPageModify from '../../hooks/useMyPageModify'

const ModifyMyPageComponent = () => {
  const {
    handleCategoryClick,
    handleImageClick,
    handleSubButtonClick,
    handleImageUpload,
    handleImageRemove,
    handlePodcastName,
    handlePodcastNameChange,
    handleNameChange,
    handleDescriptionChange,
    handleCheckboxChange,
    handleSave,
    profile,
    preProfileImage,
    categoryList,
    categorySubList,
    activeIndex,
    fileInputRef,
    isValid,
    isCheck,
    podCastRef,
    nameRef,
    descriptionRef,
    subCategoryRef,
    activeSubIndices,
    categoryRef,
  } = useMyPageModify()

  return (
    <div className="w-full p-5 space-y-6">
      <h2 className="text-xl font-bold mb-4">마이페이지</h2>

      <div className="p-4 bg-white border border-gray-300 rounded mb-4">
        <label className="block text-gray-700 mb-2">에피소드 이미지</label>
        <div className="flex items-center">
          {preProfileImage ? (
            <div className="mr-4">
              <img
                src={preProfileImage}
                alt="Episode"
                className="w-24 h-24 object-cover rounded"
              />
            </div>
          ) : (
            <div className="mr-4">
              <div className="w-24 h-24 border-dashed border-2 border-gray-300 flex items-center justify-center rounded">
                <span className="text-gray-500">미리보기</span>
              </div>
            </div>
          )}
          <div>
            {!preProfileImage && (
              <div className="border-dashed border-2 border-gray-300 p-4 text-center rounded mb-2">
                <button
                  onClick={handleImageClick}
                  className="bg-blue-700 text-white py-2 px-4 rounded mb-2"
                >
                  + 사진첨부
                </button>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  className="hidden"
                />
              </div>
            )}
            {preProfileImage && (
              <button
                onClick={handleImageRemove}
                className="bg-red-700 text-white py-2 px-4 rounded"
              >
                X 사진삭제
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">팟캐스트 이름</label>
        <input
          ref={podCastRef}
          type="text"
          defaultValue={profile.channelTitle}
          className="w-full p-2 border rounded"
          onChange={handlePodcastNameChange}
        />
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">사용자 이름</label>
        <input
          ref={nameRef}
          type="text"
          defaultValue={profile.username}
          className={`w-11/12 p-2 border rounded ${isValid === null ? '' : isValid ? 'border-red-500' : 'border-green-500'}`}
          onChange={handleNameChange}
        />
        <button
          className="w-1/12 p-2 border rounded"
          onClick={handlePodcastName}
        >
          중복확인
        </button>
        {isValid === null ? null : isValid ? (
          <div className="text-red-500 text-sm mt-2">
            사용자 이름이 이미 사용 중입니다.
          </div>
        ) : (
          <div className="text-green-500 text-sm mt-2">
            사용 가능한 이름입니다.
          </div>
        )}
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">필수 정보 설명</label>
        <textarea
          ref={descriptionRef}
          defaultValue={profile.channelDescription}
          className="w-full p-2 border rounded resize-none"
          onChange={handleDescriptionChange}
        ></textarea>
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">메인 카테고리 선택</label>
        <div className="flex space-x-4">
          {categoryList.map((item) => {
            const buttonClass =
              item.name === activeIndex
                ? 'bg-blue-500 text-white py-2 px-4 rounded'
                : 'bg-gray-300 text-gray-700 py-2 px-4 rounded'

            return (
              <button
                ref={categoryRef}
                key={item.id}
                className={buttonClass}
                onClick={() => handleCategoryClick(item.name)}
              >
                {item.name}
              </button>
            )
          })}
        </div>

        <label className="block text-gray-700 mb-2">서브 카테고리 선택</label>
        <div className="flex space-x-4">
          {categorySubList.map((item) => {
            const buttonClass = activeSubIndices.includes(item.name)
              ? 'bg-blue-500 text-white py-2 px-4 rounded'
              : 'bg-gray-300 text-gray-700 py-2 px-4 rounded'

            return (
              <button
                ref={subCategoryRef}
                key={item.id}
                className={buttonClass}
                onClick={() => handleSubButtonClick(item.name)}
              >
                {item.name}
              </button>
            )
          })}
        </div>
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">연령제한 컨텐츠</label>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="mr-2"
            onChange={handleCheckboxChange}
            checked={isCheck}
          />
          <span className="text-gray-700">연령제한이 포함된 컨텐츠입니다.</span>
        </div>
      </div>

      <div className="flex justify-end mt-4">
        <button
          onClick={handleSave}
          className="bg-blue-700 text-white py-2 px-4 rounded"
        >
          완료
        </button>
      </div>
    </div>
  )
}

export default ModifyMyPageComponent
